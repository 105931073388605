// Cause of xml encoding iso-8859-1...

export const titleCleanup = title => {
  if (typeof title === 'undefined') return '';
  if (typeof title === 'string') {
    if (!title) return '';
    return title
      .replace(' - Rødvin', '')
      .replace(' - rødvin', '')
      .replace(' - Hvidvin', '')
      .replace(' - hvidvin', '')
      .replace(' - Rosévin', '')
      .replace(' - rosévin', '')
      .replace(' - Rosevin', '')
      .replace(' - Rose', '')
      .replace(' - Rosé', '')
      .replace(' - osevin', '')
      .replace(' - Mousserende vin', '')
      .replace(' - mousserende vin', '')
      .replace(' - Mousserende', '')
      .replace(' - mousserende', '')
      .replace(' - Champagne', '')
      .replace(' - champagne', '')
      .replace(' - Portvin', '')
      .replace(' - portvin', '')
      .replace(' - Dessertvin', '')
      .replace(' - dessertvin', '')
      .replace(/â€¨/g, '')
      .replace(/â€™/g, "'")
      .replace(/Ã¢/g, 'â')
      .replace(/iÌˆ/g, 'ï')
      .replace(/Ã´/g, 'ô')
      .replace(/Ã¨/g, 'è')
      .replace(/Å¾/g, 'ž')
      .replace(/Ã‰/g, 'é')
      .replace(/eÌ/g, 'é')
      .replace(/eì/g, 'é')
      .replace(/Ã“/g, 'ó')
      .replace(/Ã±/g, 'ñ')
      .replace(/â€œ/g, '"')
      .replace(/ã/g, 'é')
      .replace(/â¨/g, '')
      .replace(/Ã¬/g, 'ì')
      .replace(/ã/g, 'é')
      .replace(/â/g, "'")
      .replace(/pãrigord/g, 'périgord')
      .replace(/lâoie/g, "l'oie")
      .replace(/allâuso/g, "all'uso")
      .replace(/âostal/g, "'ostal")
      .replace(/â€/g, '"');
  } else {
    if (!title[0]) return '';
    return title[0]
      .replace(' - Rødvin', '')
      .replace(' - rødvin', '')
      .replace(' - Hvidvin', '')
      .replace(' - hvidvin', '')
      .replace(' - Rosévin', '')
      .replace(' - rosévin', '')
      .replace(' - Rosevin', '')
      .replace(' - Rose', '')
      .replace(' - Rosé', '')
      .replace(' - osevin', '')
      .replace(' - Mousserende vin', '')
      .replace(' - mousserende vin', '')
      .replace(' - Mousserende', '')
      .replace(' - mousserende', '')
      .replace(' - Champagne', '')
      .replace(' - champagne', '')
      .replace(' - Portvin', '')
      .replace(' - portvin', '')
      .replace(' - Dessertvin', '')
      .replace(' - dessertvin', '')
      .replace(/â€¨/g, '')
      .replace(/â€™/g, "'")
      .replace(/Ã¢/g, 'â')
      .replace(/iÌˆ/g, 'ï')
      .replace(/Ã´/g, 'ô')
      .replace(/Ã¨/g, 'è')
      .replace(/Å¾/g, 'ž')
      .replace(/Ã‰/g, 'é')
      .replace(/eÌ/g, 'é')
      .replace(/eì/g, 'é')
      .replace(/Ã“/g, 'ó')
      .replace(/Ã±/g, 'ñ')
      .replace(/â€œ/g, '"')
      .replace(/ã/g, 'é')
      .replace(/â¨/g, '')
      .replace(/Ã¬/g, 'ì')
      .replace(/ã/g, 'é')
      .replace(/â/g, "'")
      .replace(/pãrigord/g, 'périgord')
      .replace(/lâoie/g, "l'oie")
      .replace(/allâuso/g, "all'uso")
      .replace(/âostal/g, "'ostal")
      .replace(/â€/g, '"');
  }
};
