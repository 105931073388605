export const handleTypeColor = category => {
  switch (category) {
    case 'redwine':
    case 'roedvin':
      return '#722f37';
    case 'whitewine':
    case 'hvidvin':
      return '#d4c52d';
    case 'rose':
      return '#ee7272';
    case 'champagne':
    case 'mousserende':
      return '#d4c52d';
    case 'portwine':
    case 'portvin':
      return '#400000';
    case 'dessertwine':
    case 'dessertvin':
      return '#400000';
    default:
      return '#722f37';
  }
};
