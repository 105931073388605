// Cause of xml encoding iso-8859-1...

export const handleEncoding = text => {
  if (typeof text === 'undefined') return '';
  if (typeof text === 'string') {
    if (!text) return '';
    return text
      .replace(/â€¨/g, '')
      .replace(/ââ/g, '')
      .replace(/Â/g, '')
      .replace(/&#13;/g, '')
      .replace(/â€™/g, "'")
      .replace(/Ã¢/g, 'â')
      .replace(/iÌ/g, 'ï')
      .replace(/Ã´/g, 'ô')
      .replace(/Ã¨/g, 'è')
      .replace(/Ã‰/g, 'é')
      .replace(/eÌ/g, 'é')
      .replace(/eì/g, 'é')
      .replace(/Ã/g, 'ó')
      .replace(/Ã“/g, 'ó')
      .replace(/Ã±/g, 'ñ')
      .replace(/â€œ/g, '"')
      .replace(/â¨/g, '')
      .replace(/Ã¬/g, 'ì')
      .replace(/â/g, "'")
      .replace(/Å¾/g, 'ž')
      .replace(/%C3%A9/g, 'é')
      .replace(/a%CC%8A/g, 'å')
      .replace(/Å¡/g, 'š')
      .replace(/âostal/g, "'ostal") //Special fix for L'ostal from Bayvine
      .replace(/â€/g, '"');
  } else {
    if (!text[0]) return '';
    return text[0]
      .replace(/â€¨/g, '')
      .replace(/ââ/g, '')
      .replace(/Â/g, '')
      .replace(/&#13;/g, '')
      .replace(/â€™/g, "'")
      .replace(/Ã¢/g, 'â')
      .replace(/iÌˆ/g, 'ï')
      .replace(/Ã´/g, 'ô')
      .replace(/Ã¨/g, 'è')
      .replace(/Ã‰/g, 'é')
      .replace(/eÌ/g, 'é')
      .replace(/eì/g, 'é')
      .replace(/Å¾/g, 'ž')
      .replace(/Ã/g, 'Ó')
      .replace(/Ã“/g, 'ó')
      .replace(/a%CC%8A/g, 'å')
      .replace(/Ã±/g, 'ñ')
      .replace(/â€œ/g, '"')
      .replace(/%C3%A9/g, 'é')
      .replace(/â¨/g, '')
      .replace(/â/g, "'")
      .replace(/Å¡/g, 'š')
      .replace(/Ã¬/g, 'ì')
      .replace(/âostal/g, "'ostal") //Special fix for L'ostal from Bayvine
      .replace(/â€/g, '"');
  }
};
