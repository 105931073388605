import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { handleTitle } from '../utils/handleTitle';
import { handleTypeColor } from '../utils/typeColor';

const Container = styled.div`
  min-height: 220px;
  text-align: center;
  margin: 22px auto;
`;

const Title = styled.h2`
  font-size: 1.6rem;{
    allSitePage(filter: {context: {productType: {eq: "roedvin"}, isProduct: {eq: "true"}}}) {
      edges {
        node {
          path
          context {
            productTitle
            productPrice
            productOldprice
            productLink
            productImage
            productDescription
            productCategory
            productAvailable
            productType
          }
        }
      }
    }
  }
  
  span {
    font-style: italic;
  }
`;

function RedirectPage({
  pageContext: { productLink, productOwner, productTitle, productType }
}) {
  React.useEffect(() => {
    setTimeout(() => {
      window.location.replace(productLink[0]);
    }, 200);
  }, [productLink]);

  return (
    <React.Fragment>
      <SEO
        title={'Tak fordi du brugte Vinkammeret'}
        description={
          'Vi håber at du fandt vinen du søgte. Vi tager dig videre til forhandleren.'
        }
      />
      <Layout
        themeColor={handleTypeColor(productType)}
        title={'Tak fordi du brugte Vinkammeret'}
        withoutSeo
      >
        <Container>
          <Title>
            Vi håber at <span>{handleTitle(productTitle[0])}</span>
            <br /> var vinen du ledte efter
          </Title>
          <p>Du føres nu til forhandleren {productOwner?.[0] || "DH Wines"}...</p>
        </Container>
      </Layout>
    </React.Fragment>
  );
}

export default RedirectPage;
